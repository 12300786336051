import React from "react"
import Panel from "./core/Panel"
import PropTypes from "prop-types"
import safeGetImage from "../utils/safeGetImage"

const WhoWeAreHero = ({ hero }) => {
  const { title, subtitle, heroBackgroundImage } = hero,
        imgSrc = safeGetImage(heroBackgroundImage);

  return (
    <Panel className="who-we-are-hero">
      <div className="who-we-are-hero__container">
        <div className="who-we-are-hero__text">
          <h1 className="who-we-are-hero__heading">{ title }</h1>
          <p className="who-we-are-hero__body">{ subtitle }</p>
        </div>
        <img className="who-we-are-hero__image " src={ imgSrc } alt=""/> 
      </div>
    </Panel>
  )
}

export default WhoWeAreHero

WhoWeAreHero.propTypes = {
  hero: PropTypes.object.isRequired,
}