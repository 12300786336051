import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import WhoWeAreHero from "../components/WhoWeAreHero"
import ContactForm from "../components/ContactForm"

const Contact = ({data}) => {
  const { hero } = data.mdx.frontmatter
  return (
    <>
      <SEO title="Contact Us" />
      <WhoWeAreHero hero={hero} />
      <ContactForm />
    </>
  )
}

export default Contact

export const pageQuery = graphql`
  query Contact {
    mdx(fileAbsolutePath: { regex: "/content/contact-us/" }) {
      frontmatter {
        hero {
          title
          subtitle
          heroBackgroundImage
        }
      }
    }
  }
`